<template>
  <section class="list container">
    <div id="results" v-if="!hideMe">
      <div class="preheader d-flex justify-content-between" v-if="title">
        <h2>{{ title }}</h2>
        <ToggleArrow v-if="toggle === true" @click="handleToggleClick" section="toggleMe" :status="internalToggle">
        </ToggleArrow>
      </div>
      <div>
        <header v-if="internalToggle && !hideHeader" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div
            v-if="internalToggle && !!items && items.length === 0"
            class="d-flex align-items-center flex-wrap flex-sm-nowrap w-480"
          >
            <div>
              {{ i18n['data-table'].tcNoRecordsFound }}
            </div>
          </div>
          <div v-if="!!items && items.length > 0" class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <div v-show="showExportBtn">
              <a
                class="btn btn-dark flex-0 mr-2"
                v-if="csvUrl"
                download
                @click.prevent="downloadReport(csvUrl, reportName, 'csv')"
              >
                {{ i18n['data-table'].tcExportCSV }}
              </a>
              <a
                class="btn btn-dark flex-0 mr-2"
                v-if="pdfUrl"
                download
                @click.prevent="downloadReport(pdfUrl, reportName, 'pdf')"
              >
                {{ i18n['data-table'].tcExportPDF }}
              </a>
            </div>
            <b-form-input
              v-model="filter"
              @input="handleSearchTermChange"
              :placeholder="i18n['data-table'].tcSearch"
              class="search w-100-sd mw-100-sd mb-3 mb-sm-0 d-block w-100-sd order-1 order-sm-2"
            ></b-form-input>
          </div>
          <div
            v-if="addItems.action === 'addChurch'"
            class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd"
          >
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="selectedChurchTypes"
                :aria-describedby="ariaDescribedby"
                :options="churchTypeOptions"
                @change="handleChurchTypeChange"
                plain
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button
              class="btn btn-primary flex-0 d-block w-100-sd"
              v-if="addItems.display && includeAddButton"
              @click="triggerAction(addItems.action)"
            >
              {{ addItems.text }}
            </button>
          </div>
        </header>
        <main v-if="internalToggle && !!items && items.length > 0" class="table-responsive">
          <b-table
            class="g-table"
            :fields="fields"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            sort-icon-left
            small
          >
            <div class="d-inline-flex align-items-start" slot="HEAD_Media" slot-scope="data" @click="log(data)">
              <b-button v-if="data.field.cTooltip" id="button-1" class="tooltip-button mr-1" style="top: -7px"
                >?
              </b-button>
              <b-tooltip v-if="data.field.cTooltip" target="button-1" :title="data.field.cTooltip"></b-tooltip>
              {{ data.label }}
            </div>
            <template v-slot:cell(member_name)="data">
              <a href="#" style="underline" @click.prevent="individualStoreKeyAndRedirect(data.item.ind_key)">{{
                data.value
              }}</a>
            </template>
            <template v-slot:cell(friend_name)="data">
              <a href="#" style="underline" @click.prevent="individualStoreKeyAndRedirect(data.item.ind_key)">{{
                data.value
              }}</a>
            </template>
            <template v-slot:cell(sort_name)="data">
              <!-- If the data table is rendering notes, update the sort name to navigate to the contacted
                  by individual's profile. -->
              <a
                v-if="!!data.item.nte_contact_by_ind_key"
                href="#"
                style="underline"
                @click.prevent="individualStoreKeyAndRedirect(data.item.nte_contact_by_ind_key)"
                >{{ data.value }}</a
              >
              <!-- By default, the sort_name column does not provide navigation. -->
              <div v-else>{{ data.value }}</div>
            </template>
            <template v-slot:cell(view_key)="data">
              <a href="#" style="underline" @click.prevent="viewKeyAndRedirect(data)">
                {{ data.value }}
              </a>
            </template>
            <template v-slot:cell(presentation_date)="data">
              <a href="#" style="underline" @click.prevent="presentationStoreKeyAndRedirect(data)">{{ data.value }}</a>
            </template>
            <template v-slot:cell(contact_date)="data">
              <a href="#" style="underline" @click.prevent="visitStoreKeyAndRedirect(data)">{{ data.value }}</a>
            </template>
            <template v-slot:cell(church_name)="data">
              <a href="#" style="margin-right: 5px" @click.prevent="churchStoreKeyAndRedirect(data)">{{
                data.value
              }}</a>
            </template>
            <template v-slot:cell(facility_name)="data">
              <a
                v-if="listType == 'facility'"
                href="#"
                style="underline"
                @click.prevent="facilityStoreKeyAndRedirect(data)"
                >{{ data.value }} {{ data.list_type }}</a
              >
              <a
                v-if="listType == 'restocking_visit'"
                href="#"
                style="underline"
                @click.prevent="facilityStoreKeyAndRedirect(data)"
                >{{ data.value }}</a
              >
              <a
                v-if="listType == 'video_showing'"
                href="#"
                style="underline"
                @click.prevent="facilityStoreKeyAndRedirect(data)"
                >{{ data.value }}</a
              >
            </template>
            <template v-slot:cell(wit_name)="data">
              <a href="#" style="underline" @click.prevent="encounterStoreAndRedirect(data)">{{ data.value }}</a>
            </template>
            <template v-slot:cell(gideon_church_rep_sort)="data">
              <a
                href="#"
                style="underline"
                @click.prevent="individualStoreKeyAndRedirect(data.item.gideon_church_rep_ind_key)"
                >{{ data.item.gideon_church_rep }}</a
              >
            </template>
            <template v-slot:cell(speaker)="data">
              <span
                v-if="data.item.speaker_cnt_key === demograph.country_key || data.item.speaker === 'Multiple Speakers'"
              >
                <a
                  v-if="data.item.ind_key"
                  href="#"
                  style="underline"
                  @click.prevent="individualStoreKeyAndRedirect(data.item.ind_key)"
                  >{{ data.value }}</a
                >
                <!-- This v-else is here in case of multiple presenters--multiple presenters have a null ind_key
              If this is the case, we want to redirect to the presentation details
              In the future it might be better to have these click events simply emit out to parent components -->
                <a v-else href="#" style="underline" @click.prevent="presentationStoreKeyAndRedirect(data)">{{
                  data.value
                }}</a>
              </span>
              <span
                v-if="data.item.speaker_cnt_key !== demograph.country_key && data.item.speaker !== 'Multiple Speakers'"
                >{{ data.value }}</span
              >
            </template>
            <template v-slot:cell(location_name)="data">
              <a @click.prevent="churchStoreKeyAndRedirect(data)" href="#">{{ data.value }}</a>
            </template>
            <div slot="date_qualified" slot-scope="data">
              <span v-if="type(data.value) == 'string'">{{ data.value }}</span>
              <span
                v-if="type(data.value) === 'object'"
                :id="notUndefined(data.value.id)"
                :class="{ tooltip_text: data.value.tooltip }"
                >{{ data.value.text }}</span
              >
              <b-tooltip
                v-if="data.value.tooltip && type(data.value) === 'object'"
                :target="data.value.id"
                :title="data.value.tooltip"
              ></b-tooltip>
            </div>

            <span slot="Media" slot-scope="data">
              <div class="icon-box" :class="{ checked: data.value }"></div>
            </span>

            <span slot="History" slot-scope="data">
              <a :href="data.value">{{ i18n['data-table'].tcView }}</a>
            </span>
            <template v-slot:cell(history_ind_key)="data">
              <a
                href="#"
                style="underline"
                @click.prevent="presenterHistoryStoreKeyAndRedirect(data.item.history_ind_key)"
                >{{ i18n['data-table'].tcViewHistory }}</a
              >
            </template>
            <template v-slot:cell(documents)="data">
              <div class="d-flex">
                <div style="margin-right: 10px; cursor: pointer;" v-for="(file, index) in data.value" :key="index">
                  <a
                    v-if="file.type === 'pdf'"
                    @click="handleReportIconClick(file.reportKey, file.reportParentKey, 'pdf')"
                    title="PDF"
                  >
                    <iFile></iFile>
                  </a>
                  <a
                    v-if="file.type === 'csv'"
                    @click="handleReportIconClick(file.reportKey, file.reportParentKey, 'csv')"
                    title="CSV"
                  >
                    <iCSV></iCSV>
                  </a>
                  <a
                    v-if="file.type === 'excel'"
                    @click="handleReportIconClick(file.reportKey, file.reportParentKey, 'excel')"
                    title="Excel"
                  >
                    <iExcel></iExcel>
                  </a>
                </div>
              </div>
            </template>
            <template v-slot:cell(expdate)="data">
              <dl class="expires">{{ data.value.substr(0, 10) }}</dl>
            </template>
            <template v-slot:cell(activity_date)="data">
              <a
                href="#"
                v-if="listType == 'restocking_visit'"
                style="underline"
                @click.prevent="restockingStoreKeyAndRedirect(data)"
                >{{ data.value }}</a
              >
              <a
                href="#"
                v-else-if="listType == 'video_showing'"
                style="underline"
                @click.prevent="videoStoreKeyAndRedirect(data)"
                >{{ data.value }}</a
              >
              <!-- Make sure the date returns its value by default. -->
              <div v-else>{{ data.value }}</div>
            </template>
            <template v-slot:cell(sortdate)="data">
              <dl class="one_of_three">{{ data.value }}</dl>
            </template>
            <template v-slot:cell(category)="data">
              <dl>{{ data.value }}</dl>
            </template>
            <template v-slot:cell(msg_title)="data">
              <dl class="two_of_three">{{ data.value }}</dl>
            </template>
            <template v-slot:cell(msg_message_id)="data">
              <dl class="three_of_three">
                <a href="#" @click.prevent="triggerActionWithValue('editAnnouncement', data.value)">
                  <ServiceEdit />
                </a>
                <a
                  style="margin-left: 30px"
                  href="#"
                  @click.prevent="triggerActionWithValue('deleteAnnouncement', data.value)"
                >
                  <ServiceDelete />
                </a>
              </dl>
            </template>
            <template v-slot:cell(offering_amount)="data">
              {{ !data.value ? '' : data.value }}
            </template>
          </b-table>
        </main>
        <footer v-if="internalToggle" class="d-flex align-items-center justify-content-between">
          <div class="prev" @click="prevPage" :class="{ hidden: this.currentPage == 1 }">
            <left-arrow></left-arrow>
          </div>
          <nav class="d-flex align-items-center justify-content-center">
            <div class="show d-flex align-items-center justify-content-end">
              <div class="lower">{{ i18n['data-table'].tcShow }}</div>
              <button :class="{ active: this.perPage == 20 }" @click="updateNumber(20)">20</button>
              <button :class="{ active: this.perPage == 50 }" @click="updateNumber(50)">50</button>
              <button :class="{ active: this.perPage == 80 }" @click="updateNumber(80)">80</button>
            </div>
            <div class="sep"></div>
            <div class="pagination justify-self">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                :hide-goto-end-buttons="true"
                @change="pageChanged"
              ></b-pagination>
            </div>
          </nav>
          <div
            class="next"
            v-if="!!items && items.length > 0"
            @click="nextPage"
            :class="{
              hidden: Math.ceil(this.items.length / this.perPage) == this.currentPage
            }"
          >
            <right-arrow></right-arrow>
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import iCSV from '@/assets/svgs/iCSV.vue'
import iExcel from '@/assets/svgs/iExcel.vue'
import iFile from '@/assets/svgs/iFile.vue'
import leftArrow from '@/assets/svgs/arrow-left.vue'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import rightArrow from '@/assets/svgs/arrow-right.vue'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import ServiceEdit from '@/assets/svgs/service-card-edit.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'

export default {
  name: 'data-table',
  mixins: [reportDownloadMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'data-table': {
            tcExportCSV: 'Export XLS',
            tcExportPDF: 'Export PDF',
            tcHidden: 'Hidden',
            tcIncomplete: 'Incomplete',
            tcIneligible: 'Ineligible',
            tcNo: 'No',
            tcSearch: 'Search',
            tcShow: 'Show',
            tcNoRecordsFound: 'No records were found.',
            tcView: 'View',
            tcViewHistory: 'View History',
            tcYes: 'Yes'
          }
        }
      }
    },
    fields: Array,
    items: Array,
    listType: String,
    csvUrl: {
      type: String,
      default: ''
    },
    pdfUrl: {
      type: String,
      default: ''
    },
    reportName: {
      type: String,
      default: 'Report'
    },
    showExportBtn: {
      type: Boolean,
      default: true
    },
    searchTerm: {
      type: String,
      default: 'genericTerm'
    },
    hiddenItems: {
      type: Object,
      default: () => ({
        key: null,
        display: false
      })
    },
    addItems: {
      type: Object,
      default: () => ({
        text: 'Add Church',
        display: true,
        action: '#'
      })
    },
    hideHeader: { type: Boolean, default: false },
    includeAddButton: { type: Boolean, default: false },
    title: String,
    toggle: Boolean,
    toggleMe: { type: Boolean, default: true },
    hideMe: { type: Boolean, default: false },
    propSortBy: {
      type: String,
      default: 'Name'
    },
    propSortDesc: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Church Table Settings
      sortBy: 'Church Name',
      sortDesc: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      internalToggle: true,
      selectedChurchTypes: [],
      churchTypeOptions: [
        { text: 'Hidden', value: 'hidden' },
        { text: 'Ineligible', value: 'ineligible' }
      ]
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setReportFileType: 'reportParameter/setReportFileType',
      setReportParamParentKey: 'reportParameter/setReportParamParentKey',
      setSearchTerm: 'search/setSearchTerm',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
      setSelectedEncounter: 'prayerAndWitnessing/setSelectedEncounter',
      setSelectedEncounterKey: 'prayerAndWitnessing/setSelectedEncounterKey',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedGCFOrgKey: 'card/setSelectedGCFOrgKey',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey',
      setSelectedReportKey: 'reportParameter/setSelectedReportKey',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedTestimonyKey: 'testimony/setSelectedTestimonyKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey'
    }),
    handleChurchTypeChange() {
      this.$emit('churchDisplayTypeChange', this.selectedChurchTypes)
    },
    pageChanged() {
      document.getElementById('results').scrollIntoView()
    },
    handleToggleClick() {
      this.internalToggle = !this.internalToggle
    },
    async handleSearchTermChange() {
      let obj = `{ "${this.searchTerm}": "${this.filter}" }`
      await this.setSearchTerm(JSON.parse(obj))
    },
    updateNumber(num) {
      this.perPage = num
    },
    prevPage() {
      if (this.currentPage == 1) {
        return
      }
      this.currentPage--
      document.getElementById('results').scrollIntoView()
    },
    nextPage() {
      if (!this.items) {
        return 1
      }
      if (Math.ceil(this.items.length / this.perPage) == this.currentPage) {
        return
      }
      this.currentPage++
      document.getElementById('results').scrollIntoView()
    },
    triggerAction(action) {
      this.$emit(action)
    },
    triggerActionWithValue(action, value) {
      this.$emit(action, value)
    },
    log(item) {},
    type(val) {
      return typeof val
    },
    notUndefined(val) {
      return !val ? '' : val
    },
    async presentationStoreKeyAndRedirect(data) {
      await this.setSelectedMeetingKey(data.item.mtg_key)
      this.$router.push({ path: '/programs/cm/churchProfile/view-church-presentation' })
    },
    async visitStoreKeyAndRedirect(data) {
      await this.setSelectedVisitKey(data.item.nte_key)
      this.setRouterBackLink(this.$route.name)
      this.$router.push({ path: '/programs/cm/churchProfile/view-church-visit' })
    },
    async churchStoreKeyAndRedirect(data) {
      const { nte_entity_key, church_key, location_key, chu_org_key, gcf_org_key } = data.item
      await this.setSelectedChurchKey(nte_entity_key || church_key || location_key || chu_org_key || gcf_org_key)
      this.$router.push({ path: '/programs/cm/church-profile' })
    },
    async facilityStoreKeyAndRedirect(data) {
      await this.setSelectedFacilityKey(data.item.gcf_org_key)
      this.$router.push({ path: '/programs/gc/profile/summary/card_profile' })
    },
    async restockingStoreKeyAndRedirect(data) {
      await this.setSelectedFacilityKey(data.item.gcf_org_key)
      await this.setSelectedVisitKey(data.item.mtg_key)
      this.$router.push({ path: '/programs/gc/profile/restocking-visit-details' })
    },
    async videoStoreKeyAndRedirect(data) {
      await this.setSelectedFacilityKey(data.item.gcf_org_key)
      await this.setSelectedMeetingKey(data.item.mtg_key)
      await this.setSelectedVisitKey(data.item.mtg_key)
      this.$router.push({ path: '/programs/gc/profile/video-visit' })
    },
    async individualStoreKeyAndRedirect(data) {
      await this.setSelectedIndividualKey(data)
      this.$router.push({ path: '/programs/mb/member-profile/' })
    },
    async presenterHistoryStoreKeyAndRedirect(data) {
      await this.setSelectedIndividualKey(data)
      this.$router.push({ path: '/programs/cm/presenter-history' })
    },
    async viewKeyAndRedirect(data) {
      this.$router.push({ name: `${data.value}` })
    },
    async encounterStoreAndRedirect(data) {
      await this.setSelectedEncounter(data.item)
      this.$router.push({ path: '/programs/pw/add-witnessing-encounter' })
    },
    async handleReportIconClick(reportKey, reportParentKey, format) {
      // go to the parameters screen
      let rpt = constantData.secure_report_array.find(raf => raf.key === reportKey)
      if (!!rpt) {
        this.$router.push({ name: rpt.name })
      } else {
        await this.setReportParamParentKey(reportParentKey)
        await this.setSelectedReportKey(reportKey)
        await this.setReportFileType(format)
        this.$router.push({ name: 'report-parameters' })
      }
    }
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      searchTerms: 'search/searchTerms'
    }),
    rows() {
      return this.items ? this.items.length : 0
    }
  },
  mounted() {
    this.internalToggle = this.toggleMe
  },
  created() {
    this.sortBy = this.propSortBy
    this.sortDesc = this.propSortDesc
    this.perPage = constantData.per_page_default
    this.filter = this.searchTerms[this.searchTerm]
  },
  watch: {
    i18n: function(newVal) {
      if (newVal['data-table']) {
        const translatedTypes = [
          { text: newVal['data-table'].tcHidden || 'Hidden', value: 'hidden' },
          { text: newVal['data-table'].tcIneligible || 'Ineligible', value: 'ineligible' }
        ]
        return this.$set(this, 'churchTypeOptions', translatedTypes)
      }
    }
  },
  components: {
    iCSV: iCSV,
    iExcel: iExcel,
    iFile: iFile,
    leftArrow: leftArrow,
    rightArrow: rightArrow,
    ServiceDelete: ServiceDelete,
    ServiceEdit: ServiceEdit,
    ToggleArrow: ToggleArrow
  }
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

th[role='columnheader'] > div {
  padding-left: 15px;
}

.w-480 {
  width: 497px;
}

.form-check-label {
  margin-bottom: 0;
  margin-right: 20px;
  text-transform: uppercase;
  padding: 2px;
}

.form-check-input {
  font-weight: 600;
  transform: scale(1.6);
}

input.form-check-input[value='hidden']:checked:before {
  background-color: #c3e6cb;
}

input.form-check-input[value='ineligible']:checked:before {
  background-color: #bee5eb;
}

.form-check-input[value='hidden'] + label {
  background-color: #c3e6cb;
}

.form-check-input[value='ineligible'] + label {
  background-color: #bee5eb;
}

.g-table th:first-of-type,
.g-table td:first-of-type {
  width: 150px !important;
}

.g-table td:nth-of-type(5) {
  padding-left: 30px;
}

.g-table th,
.g-table td {
  vertical-align: top;
  padding: 10px 0 10px 0 !important;
}

.one_of_three {
  width: 115px !important;
}

.two_of_three {
  width: 400px !important;
}

.preheader {
  padding: 30px 32px;
  align-items: center;

  @include breakpoint(sm) {
    padding-left: 23px;
    padding-right: 23px;
  }

  h2 {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4;
    line-height: 44px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      padding-top: 0;
    }
  }
}

.lower {
  text-transform: lowercase;
}
</style>
